import { MDXProvider } from "@mdx-js/react"
import * as React from "react"
import { Layout } from "../components/layout"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { Card, Media, Columns } from "../components/common"

const ArticleContent = styled.div`
  margin: 0 auto;
  padding: 1rem;
  width: 1000px;
  @media (max-width: 1000px) {
    width: 100vw;
  }
`
const ArticleSpacer = styled.div`
  width: 90%;
  height: 1px;
  background: grey;
  margin: 1rem auto;
`

const components = {
  h1: (props) => (
    <h1 className="title is-1" {...props} />
  ),
  h2: (props) => (
    <h2 className="title is-2" {...props} />
  ),
  h3: (props) => (
    <h3 className="title is-3" {...props} />
  ),
  h4: (props) => (
    <h4 className="title is-4" {...props} />
  ),
  h5: (props) => (
    <h5 className="title is-5" {...props} />
  ),
  h6: (props) => (
    <h6 className="title is-6" {...props} />
  ),
  p: (props) => (
    <p style={{
      textIndent: '2em',
      fontSize: '1rem',
      marginBottom: '1.5rem',
    }} {...props} />
  ),
  img: (props) => (
    <figure className="image" style={{ maxWidth: '95vw', }}>
      <img {...props} />
      {props.alt ? <p style={{ textAlign: 'center', color: 'grey', }}>
        {props.alt}
      </p> : null}
    </figure >
  ),
  Card,
  Media,
  Columns,
}

const PostPage = ({ data, children }) => {
  console.log(data)
  return (
    <Layout>
      <ArticleContent>
        <h1 className="title" style={{ fontSize: '3.5rem' }}>{data.mdx.frontmatter.title}</h1>
        <p className="subtitle is-6" color="grey">
          {`${data.mdx.frontmatter.date.split('T')[0]} 来源：${data.mdx.frontmatter.source}`}
        </p>
        <ArticleSpacer></ArticleSpacer>
        <MDXProvider components={components}>
          {children}
        </MDXProvider>
      </ArticleContent>
    </Layout>
  )
}

export default PostPage

// 根据github上的issue，如果像示例中一样使用“String!”强制
// 要求字符串类型则会报错，故去掉!。
// https://github.com/gatsbyjs/gatsby/issues/8242
// 原官方示例见：https://www.gatsbyjs.com/docs/how-to/routing/mdx/#make-a-layout-template-for-your-posts
export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date
        source
      }
    }
  }
`

export const Head = ({ data }) => <title>{data.mdx.frontmatter.title} - 神顶峰 雪蜜网</title>